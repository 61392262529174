import { instrumentService } from '~/modules/SDK/services/instruments';
export const dealerList = [
    {
        label: '群益',
        name: 'capital',
        openAccountUrl: {
            sungop: 'https://docs.google.com/forms/d/e/1FAIpQLSdPT-GLfJUQTNgciNKF0rdFk2EYk_YYBmuOjYIHgXgA-cRfCQ/viewform',
            weng888: 'https://docs.google.com/forms/d/e/1FAIpQLSelCwbjv2wpsGd7G0I6IouLwW3sOmezek6ttLAXjit-H9P7xw/viewform',
            weng888_stock: 'https://docs.google.com/forms/d/e/1FAIpQLSelCwbjv2wpsGd7G0I6IouLwW3sOmezek6ttLAXjit-H9P7xw/viewform',
        },
    },
    {
        label: '國票',
        name: 'ibf',
        openAccountUrl: {
            sungop: 'https://docs.google.com/forms/d/e/1FAIpQLSe2OKuI3Mxe_h15Q6xDOcnZ6WSc1oak1RnDdwdj3sutNT3IRA/viewform',
            weng888: 'https://docs.google.com/forms/d/e/1FAIpQLSdSt_YDF6n1dX4oMQyOL6x352qJxTDS20qtppACralImiWfGA/viewform',
            weng888_stock: 'https://docs.google.com/forms/d/e/1FAIpQLSdSt_YDF6n1dX4oMQyOL6x352qJxTDS20qtppACralImiWfGA/viewform',
        },
    },
];
export const dealerOrderUrl = {
    ibf: {
        futures: {
            long: 'B',
            short: 'S',
        },
    },
    capital: {
        futures: {
            long: 'B',
            short: 'S',
        },
    },
};
// 定義券商 URL 配置
export const dealerUrlConfig = {
    capital: {
        tx: {
            baseUrl: 'https://tradeweb.capital.com.tw/capitalh5/Login.aspx?',
            params: (symbol, longShort) => `source=zz&DefaultCond=F&DisplayCond=F&FuntureID=${symbol}&FuntureBS=${dealerOrderUrl.capital.futures[longShort]}`,
        },
        stockfuture: {
            baseUrl: 'https://tradeweb.capital.com.tw/capitalh5/Login.aspx?',
            params: (symbol, longShort) => `source=zz&DefaultCond=F&DisplayCond=F&FuntureID=${'FI' + symbol}&FuntureBS=${dealerOrderUrl.capital.futures[longShort]}`,
        },
    },
    ibf: {
        tx: {
            baseUrl: 'https://itrade.ibfs.com.tw/APP/FuturesLite/Forder.aspx?',
            params: (symbol, longShort, agentName) => `&no=${translateSymbolToIbfContractName(symbol)}&volumn=1&trade=${dealerOrderUrl.ibf.futures[longShort]}&c=${agentName}`,
        },
        stockfuture: {
            baseUrl: 'https://itrade.ibfs.com.tw/APP/FuturesLite/Forder.aspx?',
            params: (symbol, longShort, agentName) => `&no=${translateSymbolToIbfContractName(symbol)}&volumn=1&trade=${dealerOrderUrl.ibf.futures[longShort]}&c=${agentName}`,
        },
        // no:商品代碼
        // volumn:數量
        // trade:買賣別，只有B與S
        // c:xxxx投顧老師代碼(名單可以再另外整理對應公司)
    },
};
// 統一處理期貨和股票期貨的合約名稱轉換
function translateSymbolToIbfContractName(symbol) {
    const instrument = instrumentService.getInstrument(symbol);
    const monthCode = instrument.symbol.frontMonth;
    if (symbol === 'FITX') {
        return 'TXF' + monthCode;
    }
    else if (symbol === 'FIMTX') {
        return 'MXF' + monthCode;
    }
    else {
        return symbol + monthCode;
    }
}
